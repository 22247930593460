<template>
  <div>
    <b-container fluid class="p-1">
      <span class=".font-weight-normal"
        >腾讯官方认证 - <b-avatar size="sm" :src="avatar" />{{
          nickname
        }}为你服务</span
      >
      <b-row>
        <b-col>
          <b-img-lazy thumbnail fluid :src="qrcode" alt="Image 2')" />
        </b-col>
      </b-row>
      按住上方二维码添加客服微信
    </b-container>
  </div>
</template>
<script>
import { BImg, BContainer, BRow, BCol, BImgLazy, BAvatar } from "bootstrap-vue";

import useJwt from "@/auth/jwt/useJwt";

export default {
  components: {
    BImg,
    BContainer,
    BRow,
    BCol,
    BImgLazy,
    BAvatar,
  },
  data() {
    return {
      code: "",
      cid: "",
      wrong: "",
      did: "",
      qrcode: "",
      nickname: "",
      avatar: "",
    };
  },
  mounted() {
    document.title = "微信认证";
    this.code = this.$route.query.code;
    this.rid = this.$route.query.rid; //推广者微信
    this.cid = this.$route.query.cid;
    this.did = this.$route.query.did;
    this.wechatnick;
    useJwt
      .querywechatqrcode({
        cid: this.cid,
        did: this.did,
        rid: this.rid,
        code: this.code,
      })
      .then((res) => {
        if (res.data.code == "0") {
          this.qrcode = res.data.data.qrcode;
          this.nickname = res.data.data.wechatnick;
          this.avatar = res.data.data.avatar;
        }
        if (res.data.code == "1") {
          var wechatid = res.data.data.wechatid;
          this.$router.push({
            name: "promoter",
            query: { cid: this.cid, wechatid: wechatid },
          });
        }
      });
  },
};
</script>